/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------
@import "fa/font-awesome.less";
@import "variables.less";
@import "mixins.less";

//
// Typography
// --------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

a {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  &:focus,
  &:visited {
    text-decoration: none;
  }
}

//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    .box-shadow(none);
  }

  .badge {
    text-shadow: none;
  }
}

// Mixin for generating new styles
.btn-styles(@btn-color: #555) {
  // #gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color, 12%));
  // .reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
  // background-repeat: repeat-x;
  // border-color: darken(@btn-color, 14%);

  &:hover,
  &:focus  {
    background-color: darken(@btn-color, 12%);
    // background-position: 0 -15px;
  }

  &:active,
  &.active {
    background-color: darken(@btn-color, 12%);
    // border-color: darken(@btn-color, 14%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken(@btn-color, 12%);
      background-image: none;
    }
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  border: none;
  border-radius: 0;

  &:active,
  &.active {
    background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default { .btn-styles(@btn-default-bg); text-shadow: 0 1px 0 #fff; border-color: #ccc; }
.btn-primary { .btn-styles(@btn-primary-bg); }
.btn-success { .btn-styles(@btn-success-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }
.btn-danger  { .btn-styles(@btn-danger-bg); }


//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
  background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  #gradient > .vertical(@start-color: lighten(@navbar-default-bg, 10%); @end-color: @navbar-default-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: @navbar-border-radius;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: darken(@navbar-default-link-active-bg, 5%); @end-color: darken(@navbar-default-link-active-bg, 2%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255,255,255,.25);
}

// Inverted navbar
.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  // text-shadow: 0 1px 0 rgba(255,255,255,.2);
  // @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  // .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@bud-light-light-blue); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }


//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}


//
// Header
// --------------------------------------------------
.header {
  background: @bud-light-med-blue url('../images/water-bg-2.jpg');
  background-attachment: fixed;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  color: @white;
  padding: 0 0 (@padding-large-vertical*2);

  @media (min-width: @screen-md) {
    padding: 0 0 (@padding-large-vertical*6);
  }
}

.logo {
  margin: 0 auto;
  padding: 0 0 @line-height-computed;
  text-align: center;

  p {
    text-transform: uppercase;
    margin: 5px 0;
  }

  img, svg {
    max-height: 80px;
    margin-left: auto;
    margin-right: auto;
  }
}

.budlight-logo {
  display: block;
  margin: @padding-large-vertical auto 0;
}

.embed-responsive {
  border: 4px solid @white;
}

.embed-hero {
  background-color: @white;
  border-width: 4px 4px 0;

  &:before {
    background: transparent url('../images/channel-frog-watermark.svg') top left no-repeat;
    background-size: contain;
    content: '';
    display: block;
    left: @padding-base-horizontal;
    opacity: .5;
    position: absolute;
    top: @padding-large-vertical;
    z-index: 999;

    height: 90px;
    width: 90px;
  }
}

.caption {
  background: fade(@black, 90%);
  border: 4px solid @white;
  border-width: 0 4px 4px;
  font-size: @font-size-h4;
  margin-bottom: @line-height-computed;
  padding: (@padding-base-vertical * 2) @padding-base-horizontal;
  text-align: center;
}

.alert {
  background-image: none;
  border-radius: 0;
  border-width: 0;
  text-shadow: none;

  span {
    font-family: @headings-font-family;
    font-size: @font-size-h3;
    float: left;
    text-transform: uppercase;
  }
  form {
    clear: both;

    @media (min-width: @screen-md) {
      clear: none;
      float: right;
    }
  }
}

.comedy-text {
//  font-weight: bold;
  padding-bottom: @padding-large-vertical;
  padding-top:    (@padding-large-vertical*2);
}

.video-thumbnail {
  background: @black;
  border: 4px solid @white;
  color: @white;
  display: block;
  margin: 0 0 @padding-large-vertical;
  position: relative;
  transition: all 2s ease;

  &:before {
    background-color: fade(@bud-light-med-blue, 75%);
    bottom: 0;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: opacity 1s ease;
  }

  &.active,
  &:hover,
  &:focus {
    border-color: @bud-light-light-blue;
    color: @white;
    cursor: pointer;
    text-decoration: none;

    &:before {
      opacity: 1;
    }
  }

  &:hover,
  &:focus {
    transform: scale(1.05);
  }

  .lower-third {
    background: fade(@black, 75%);
    bottom: 0;
    padding: @padding-base-vertical @padding-base-horizontal;
    position: absolute;
    width: 100%;

    h4 {
      font-size: @font-size-h5;
    }

    h4, p {
      margin: 0;
    }
  }
}

.frog-thumbnail {
  margin-bottom: @line-height-computed;

  @media (max-width: @screen-xs) {
    width: 100%;
  }

  .meet-the-frogs {
    color: @white;
    display: block;
    font-weight: bold;
    line-height: (@line-height-computed * 2);
    min-height: (@line-height-computed * 2);
    text-align: center;
    text-shadow: 1px 2px 4px fade(@black, 40%);
    transition: all 1s ease;

    &:hover {
      color: @bud-light-light-blue;
      cursor: pointer;
      font-size: @font-size-h4;
      text-decoration: none;
    }
  }
}

.frog-ownership {
  display: block;
  text-align: center;
  text-shadow: 1px 2px 4px fade(@black, 40%);

  a {
    color: @white;
    display: block;
    text-align: center;
    transition: all 1s ease;

    &:hover {
      color: @bud-light-light-blue;
      cursor: pointer;
      font-size: @font-size-h4;
      text-decoration: none;
    }
  }
}

.charity {
  background-color: @white;
  padding: (@padding-large-vertical*2) 0 0;
  text-align: center;

  @media (min-width: @screen-md) {
    background-image: url('../images/blue-frog-bg.jpg');
    background-position: top left;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    padding: (@padding-large-vertical*6) 0;
    text-align: left;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.0125);
    }
  }

  h2 {
    color: @bud-light-med-blue;
  }

  &__buttons {
    clear: both;
    padding: (@padding-large-vertical*2) 0;
  }

  .blue-frog {
    img {
      margin: 0 auto;
    }
  }
}

.budlight-beer {
  background-attachment: fixed;
  background-color: @bud-light-med-blue;
  background-image: url('../images/budlight-bg.png');
  // background-image: url('../images/budlight-bg.svg');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  color: @white;
  padding: (@padding-large-vertical*1) 0;
  text-align: center;
  text-shadow: 1px 2px 4px fade(@black, 40%);

  @media (min-width: @screen-md) {
    padding: (@padding-large-vertical*6) 0;
  }

  .embed-responsive {
    margin: @line-height-computed 0 (@line-height-computed * 1.5);
  }

  a {
    color: @white;
    transition: color 1s ease;

    &:hover {
      color: @bud-light-dark-blue;
      text-decoration: none;
    }
  }
}


//
// Social share
// --------------------------------------------------
.social-share {
  a {
    color: @white;
    font-size: @font-size-h2;
    line-height: 1;
    transition: all .75s ease;

    &:hover,
    &:focus {
      color: @bud-light-light-blue;
      font-size: (@font-size-h1*1.5);

      // .budlight-beer & {
      //   color: @bud-light-dark-blue;
      // }
    }
  }
}


//
// Footer
// --------------------------------------------------
.footer {
  background-color: @bud-light-dark-blue;
  color: @white;
  overflow: hidden;
  padding: (@padding-large-vertical*2) 0;
  position: relative;

  @media (min-width: @screen-md) {
    padding: (@padding-large-vertical*6) 0;
  }

  .can {
    display: none;

    @media (min-width: @screen-sm) {
      background: transparent url('../images/budlight-can.png') top left no-repeat;
      display: block;
      width: 125px;
      height: 200px;
      position: absolute;
      bottom: 0;

      &--left {
        left: @padding-base-horizontal;
      }

      &--right {
        right: @padding-base-horizontal;
      }
    }
  }

  .drinkaware {
    display: block;
    margin: 0 auto;
    max-width: 200px;
  }

  &-nav {
    a {
      border-right: 1px solid;
      color: @white;
      display: block;
      font-size: @font-size-h5;
      padding-right: @padding-small-horizontal;

      &:hover {
        cursor: pointer;
      }
    }

    li:last-child {
      a {
        border-right: none;
        padding-right: none;
      }
    }
  }
}

//
// Modal
// --------------------------------------------------
.modal-content {
  border-radius: 0;
  box-shadow: none;

  dl {
    dt {
      font-family: @headings-font-family;
      font-size: @font-size-h4;
    }
    dd {
      margin-bottom: @line-height-computed;
    }
  }
}

//
// Age check
// ---------------------------------------------------
#age_check-modal, .age_check-content{background-color: rgb(0, 66, 178);}

.age_check-header {border-bottom: 0px solid rgb(0, 66, 178);}
.age_check-footer {border-top: 0px solid rgb(0, 66, 178);}

.age_check-content {
    -webkit-box-shadow: 0 0px 0px rgb(0, 66, 178);
    box-shadow: 0 0px 0px rgb(0, 66, 178);
    border: 0px solid rgb(0, 66, 178);
    margin-top: 20vh;
}

#age_check-modal{
  color: @white;

  .age-logo{
    margin: auto;
  }

  .age_check{
    text-align: center;
  }

  .age_check > p {
      text-transform: uppercase !important;
      font-family: @headings-font-family;
      font-size: @font-size-h3;
      color: @white;
      margin-bottom: 0;
      letter-spacing: .02em;
      padding: 5%;
  }

  .date-field input[type="number"] {
    -moz-appearance: textfield;
    background: fade(@bud-light-light-blue, 50%);
    border: none;
    color: @white;
    font-family: @headings-font-family;
    margin: 0;
    padding: 16px 8px;
    text-align: center;
    transition: background 0.25s ease-in-out, border-color 0.25s ease-in-out;
    width: 100%;

    &:focus,
    &:hover,
    &.active {
      background-color: @white;
      color: @bud-light-dark-blue;
      cursor: pointer;

      &::placeholder {
        color: @gray-darker;
      }
    }

    &::placeholder {
      color: @white;
    }
  }

  .age-input > .row > .col-xs-12 button {
      width: 100%;
      color: @white;
      background: #171387;
      font-size: 18px !important;
      font-family: @headings-font-family;
      text-align: center;
      border: none;
      padding: 8px 0px;
      margin-top: 25px;
      opacity: .5;
      transition: opacity 0.25s ease-in-out;

      &:hover {
        background-color: @white;
        color: @bud-light-med-blue;
        opacity: 1;
        cursor: pointer;
      }
  }

  input[type="number"].error {
      background: @white;
      padding: 13px 8px;
      border: solid 3px @brand-danger;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.intro-text{
  font-weight: bold;
  text-align: justify;
}
.logo {
  margin: 0 auto;
  padding: 20px 0 20px;
  text-align: center;
}
.logo-text{
  padding: 50px 0 20px;
}
.frog_audio_btn{
  padding: 10pt;
  font-size: 25pt;
  z-index: 1000;
  position: absolute;
  left: 92%;
}
.frog_audio_btn > a > i{
  color: #fff;
}

@media (max-width: @screen-sm) {
  .frog_audio_btn{
    left: 90%;
  }
  .logo-text {
    padding: 5px 0 5px;
  }
  .logo {
    padding: 0px 0 5px;
  }
}

@media (max-width: @screen-xs) {
  .frog_audio_btn{
    left: 80%;
  }
  .logo-text {
    padding: 5px 0 5px;
  }
  .logo {
    padding: 0px 0 5px;
  }
}

.arrow {
  position: fixed;
  top: 85%;
  left: 49%;
  text-align: center;
  z-index: 999;
}
.arrow > a{
  color: @white;
  font-size: 50pt;
  text-decoration-line: none;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
